import React from "react";
import styled from "@emotion/styled";
import { useIntl, Link } from "gatsby-plugin-intl";
import Language from "./language";

const NavigationMobile = ({ isOpen }) => {
  const intl = useIntl();
  return (
    <StyledMenu isOpen={isOpen}>
      <Link to="/" activeStyle={{ fontWeight: "600" }}>
        Home
      </Link>
      <Link to="/art-in-progress" activeStyle={{ fontWeight: "600" }}>
        Art in Progress
      </Link>
      <Link to="/inspiration" activeStyle={{ fontWeight: "600" }}>
        Inspiration
      </Link>
      <Link to="/in-person" activeStyle={{ fontWeight: "600" }}>
        In Person
      </Link>
      <Link to="/contact" activeStyle={{ fontWeight: "600" }}>
        {intl.formatMessage({ id: "contact" })}
      </Link>
      <Language />
    </StyledMenu>
  );
};

export default NavigationMobile;

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease;
  z-index: 10;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(150%)")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }

  a {
    font-size: 2.4rem;
    text-transform: uppercase;
    padding: 3rem 0;
    font-weight: 400;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 2.5rem;
      text-align: center;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;
