import React from "react";
import { css } from "@emotion/core";
import { useIntl, Link } from "gatsby-plugin-intl";

import Language from "./language";

const NavigationDesktop = () => {
  const intl = useIntl();

  return (
    <nav
      css={css`
        ${navStyles}
      `}
    >
      <div>
        <Link
          css={css`
            ${linkStyles}
          `}
          to="/"
          activeClassName="active"
        >
          Home
        </Link>
        <Link
          css={css`
            ${linkStyles}
          `}
          to="/art-in-progress"
          activeClassName="active"
        >
          Art in Progress
        </Link>
        <Link
          css={css`
            ${linkStyles}
          `}
          to="/inspiration"
          activeClassName="active"
        >
          Inspiration
        </Link>
        <Link
          css={css`
            ${linkStyles}
          `}
          to="/in-person"
          activeClassName="active"
        >
          In Person
        </Link>
        <Link
          css={css`
            ${linkStyles}
          `}
          to="/contact"
          activeClassName="active"
        >
          {intl.formatMessage({ id: "contact" })}
        </Link>
      </div>
      <Language />
    </nav>
  );
};

export default NavigationDesktop;

const navStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  height: 4rem;
  padding: 0 2rem;

  .active {
    font-weight: 600;

    &:after {
      width: 100%;
    }
  }
`;

const linkStyles = css`
  font-size: 2rem;
  text-decoration: none;
  font-weight: 300;
  margin-right: 2rem;
  position: relative;

  &:after {
    bottom: -5px;
    left: 0%;
    position: absolute;
    content: "";
    width: 0%;
    height: 2px;
    background: #393c4a;
    transition: width 0.2s ease;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`;
