import React, { useState } from "react";
import { Global, css } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import useViewport from "../hooks/use-viewPort";
import { theme } from "../theme";
import NavigationDesktop from "../components/navigation-desktop";
import Hamburger from "../components/navigation-hamburger";
import NavigationMobile from "../components/navigation-mobile";

const Layout = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          *,
          *::before,
          *::after {
            box-sizing: border-box;
            margin: 0;
            color: #393c4a;
            font-size: 62.5%;
          }

          html,
          body {
            height: 100%;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Helvetica, Arial, sans-serif, "Apple Color Emoji",
              "Segoe UI Emoji", "Segoe UI Symbol";
            /* remove margin for the main div that Gatsby mounts into */
            > div {
              margin-top: 0;
              height: 100%;

              > div {
                height: 100%;
              }
            }
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            line-height: 1.1;

            + * {
              margin-top: 0.5rem;
            }
          }

          div,
          p,
          strong {
            font-size: 1.8rem;

            @media screen and (min-width: 768px) {
              font-size: 2rem;
            }
          }

          a {
            font-size: inherit;
            color: inherit;
          }
        `}
      />
      {width < breakpoint ? (
        <>
          <NavigationMobile isOpen={isOpen} setOpen={setOpen} />
          <Hamburger isOpen={isOpen} setOpen={setOpen} />
        </>
      ) : (
        <NavigationDesktop />
      )}
      <main
        css={css`
          margin: 1rem;
          margin-top: 0;
          position: relative;
          @media screen and (min-width: 768px) {
            margin: 0 auto;
            margin-bottom: 2rem;
            max-width: 1200px;
            padding: 0 2rem;
          }
        `}
      >
        {children}
      </main>
    </ThemeProvider>
  );
};

export default Layout;
