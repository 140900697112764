import React from "react";
import { css } from "@emotion/core";
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl";

const languageName = {
  de: "de",
  en: "en",
};

const Language = () => {
  return (
    <div
      css={css`
        padding: 3rem 0;
        text-align: center;
        @media screen and (min-width: 768px) {
          padding: 0;
        }
      `}
    >
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map((language) => (
            <>
              <a
                key={language}
                onClick={() => changeLocale(language)}
                style={{
                  margin: 10,
                  cursor: `pointer`,
                  fontWeight: currentLocale === language ? "600" : "300",
                }}
              >
                {languageName[language]}
              </a>
              <span
                css={css`
                  font-size: 2rem;
                  font-weight: 300;
                  &:last-of-type {
                    display: none;
                  }
                `}
              >
                /
              </span>
            </>
          ))
        }
      </IntlContextConsumer>
    </div>
  );
};

export default Language;
