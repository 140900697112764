import React from "react";
import styled from "@emotion/styled";

const StyledBurger = styled.div`
  bottom: -4rem;
  right: -4rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 9rem;
  height: 9rem;
  background: #fff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 11;
  box-shadow: 6px 5px 10px 1px #0d0c1d;

  &:focus {
    outline: none;
  }

  div {
    width: 30%;
    height: 4px;
    background: #393c4a;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    margin-bottom: 0.5rem;
    margin-right: 2.5rem;

    :first-of-type {
      transform: ${({ isOpen }) => (isOpen ? "rotate(45deg)" : "rotate(0)")};
      margin-top: 18px;
    }

    :nth-of-type(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
      transform: ${({ isOpen }) =>
        isOpen ? "translateX(20px)" : "translateX(0)"};
    }

    :nth-of-type(3) {
      transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Hamburger = ({ isOpen, setOpen }) => {
  return (
    <StyledBurger isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Hamburger;
